import React, {useEffect,useState} from "react"
import { Helmet } from "react-helmet";
// import Modal from "react-bootstrap/Modal"
import dateFormat from "dateformat";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import get from 'lodash/get'
import { graphql } from 'gatsby'

import Banner  from '../components/Home/Banner/Banner.js'
// import StaticPageBanner  from '../components/InnerPage/StaticPageBanner/StaticPageBanner'
import {AuctionApi} from "../components/common/utils"
import Loadable from "@loadable/component"
import { isTablet, isIPad13, isIOS13, isMobile,isMobileOnly, isDesktop, browserName } from 'react-device-detect';

// import Counter  from '../components/Home/Counter/Counter.js'
const Counter = Loadable(() => import ('../components/Home/Counter/Counter.js'))

// import Advice  from '../components/Home/Advice/Advice'
const Advice = Loadable(() => import ('../components/Home/Advice/Advice'))

// import Meet_The_Team  from '../components/InnerPage/Meet_The_Team/Meet_The_Team'
const Team = Loadable(() => import ('../components/Home/OurTeam/Team'))

// import Approch  from '../components/Home/OurApproch/Approch'
const Approch = Loadable(() => import ('../components/Home/OurApproch/Approch'))

// import News  from '../components/Home/News/News'
const News = Loadable(() => import ('../components/Home/News/News'))

// import Client  from '../components/Home/OurClients/Clients'
const Client = Loadable(() => import ('../components/Home/OurClients/Clients'))

// import Started  from '../components/Home/Contact/Contact'
const Started = Loadable(() => import ('../components/Home/Contact/Contact'))

// import MemberForm from "../components/forms/member-form"
// const MemberForm = Loadable(() => import ('../components/forms/member-form'))

// import Testimonials from "../components/Testimonials/Testimonials";
const Testimonials = Loadable(() => import ('../components/Testimonials/Testimonials'))

function HomeTemplate(props) {
  var GQLPage = props.data.glstrapi?.article;
  var GQLModules = GQLPage?.Modules;

  var site_menu = props.data.glstrapi?.allMenus;
  var global_module = props.data.glstrapi?.globalModule;
  var layout = GQLPage?.Layout;

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    }) 
  }, [])

  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu_heading, setMenuHeading] = useState(false);
  const [state, setState] = useState({showMenu: false});


  var today = new Date();
  var start_date = dateFormat(today,'yyyy-mm-dd');
  // var past_end_date = parseInt(today.getFullYear() - 1) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();
  var future_end_date = parseInt(today.getFullYear() + 1) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();
  // var available_end_date = parseInt(today.getFullYear()) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();

  // const MemberPopup = (item, popupType) =>{
  //   setPopupType(popupType);
  //   setMembDet(item);
  //   setIsOpen(true);
  // }

  const openHam = () => {
    setOpen(!open);
  };

  const openSearch = (menu_title) => {
    setSearch(!search);
    handlerClick();
    setMenuHeading(menu_title)
  };
  const closeSearch = () => {
    setSearch(!search);
    handlerClick();
  };

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
    if (state.showMenu == true) {
        document.body.className = '';
    }
    else {
        document.body.classList.add("overflow-hidden");
    }
  }

  var counter_module = GQLModules && GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerBottom");
  
  return ( <>
    {GQLPage && 
    <Layout GQLPage={GQLPage} classNames={GQLPage?.Page_Class} closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open} popular_search_slug={GQLPage?.select_menu?.Primary_URL} >
        
        <SEO title={GQLPage?.Meta_Title} description={GQLPage?.Meta_Description} Robots={GQLPage?.select_menu?.Robots} image={GQLPage?.Banner_Image?.url}/>
        
        <Helmet bodyAttributes={{ class: `body_cls` }}>

          {GQLPage?.select_menu?.Type === "Auctions" && 
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10810490027"></script>  
          }
          
        </Helmet>
        {/* =======Banner slider js Start=======*/}
        { isDesktop &&  <Banner GQLPage={GQLPage} CTA_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerCtAs")} Slider_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentSlider")} openSearch={openSearch} openHam={openHam} search={search} open={open} api_link={`${AuctionApi.future}&startDate=${start_date}&endDate=${future_end_date}`} /> }
        { isMobile && <Banner GQLPage={GQLPage} CTA_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerCtAs")} Slider_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentSlider")} openSearch={openSearch} openHam={openHam} search={search} open={open} api_link={`${AuctionApi.future}&startDate=${start_date}&endDate=${future_end_date}`} /> }


        {/* Banner slider js End */}
       
        {/* =======Counter section js start======= */}
        {
          counter_module.length > 0 && counter_module.map((Module, i) =>{
            return(
              <Counter Module={Module} Layout={GQLPage.Layout} Menu_Link={GQLPage.select_menu} />
            )
          })
        }
          {/* ======= Counter section js end ======= */}

               {
          GQLModules.map((Module, i) => {
            return(
              <>
                
                {Module.__typename === "GLSTRAPI_ComponentComponentIntroComponent" && <Advice GQLPage={GQLPage} Module={Module} location={props.location} openSearch={openSearch} openHam={openHam} search={search} open={open} Menu_Link={GQLPage.select_menu}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentContentImageSection" && renderComponent && <Approch Module={Module} openSearch={openSearch} Menu_Link={GQLPage.select_menu}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentShowNewsInsights" && renderComponent  && Module.Show_Hide && <News Module={Module} page={GQLPage}  Alias={GQLPage.Alias} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentThreeBlocksComponents" && renderComponent && <Client Module={Module} GQLPage={GQLPage}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentReviewsModule" && renderComponent  && <Testimonials Module={Module} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentOurPeopleComponent" && renderComponent && <Team Module={Module} Alias={GQLPage.Alias}/> }
                          
                {Module.__typename === "GLSTRAPI_ComponentComponentLetGetStarted" && renderComponent && <Started Module={Module} Menu_Link={GQLPage.select_menu} /> }           
                
              </>
            )
          })
        }

      {/* {
        popupType && 
        <Modal
          id="popup_modal_form"
          show={isOpen}
          onHide={() => setIsOpen(false)}
          backdrop="static"
          keyboard={false}
          className="member-contact-modal"
      > 
          {
            popupType === "Member_Form" && <>
              <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact - ${membDet.Name}`} formLabel={`Person ${membDet.Name}`} />
              </Modal.Body>
            </>
          }          
        </Modal>
      } */}

    </Layout>}
    </>
    )
}   

export default HomeTemplate


export const pageQuery = graphql`
  query HomeQuery($articleId: ID!) {

    glstrapi {

      globalModule {
        Client_Reviews_BG {
          url
          alternativeText
        }
      }

      article(id: $articleId, publicationState: LIVE) {
        Alias
        Banner_Content
        Banner_Heading
        Banner_Image {
          url
          alternativeText
        }
        Layout
        Meta_Title
        Meta_Description
        Page_Class
        Read_More_Content
        Page_Content
        Pagename
        id
        imagetransforms
        Form_List
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        select_menu {
          Label
          Primary_URL
          Type
          Sub_Type
          Form_User_ID
          Robots
          popular_searches {
            Content
            Name
          }
          Main_Parent {
            Label
            Primary_URL
          }        
          Secondary_Menu {
            Primary_URL
            Label
            Main_Parent {
              Label
              Primary_URL
            } 
          }
          nested_menus {
            Primary_URL
            Label
          }
        }

        Modules {
          __typename

          ... on GLSTRAPI_ComponentComponentSlider {
            id
            Slider_Module {
              Silder_Heading
              Slider_Content
              Slider_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 2000
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }  
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentReviewsModule {
            id
            __typename
            Heading
            Review_content {
              Review_content
              Review_name
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentBannerCtAs {            
            Banner_Video_Link
            Banner_Video_Label
            Show_Search_Box
            Show_Banner_Filter
            Banner_CTA_Module {
              CTA_Link
              CTA_Label
              id
              Icon_ClassName
            }
          }

          ... on GLSTRAPI_ComponentComponentBannerBottom {
            Banner_Bottom_Module {
              id
              Link
              Heading
              Content
            }
          }
          
          ... on GLSTRAPI_ComponentComponentIntroComponent {
            id            
            Intro_Content
            Intro_Heading
            CTA_Label
            CTA_Link
            CTA_Label_2
            CTA_Link_2
            Accordion_Module {
              Accordion_Content
              Accordion_Heading
              id
            }
            Intro_Rightside_Module {
              Intro_Rightside_Content
              Intro_Rightside_Heading
              Intro_Rightside_Link
              id
            }

            Team_Section {
              Heading
              Select_People{
                Sort
                people {
                  Name
                  id
                  URL
                  Departments
                  Level
                  people_department{
                    id
                    Name
                  }
                  people_departments {
                    id
                    Name
                  }
                  people_services{
                    id
                    Name
                  }
                  Email_ID
                  Form_Queue_ID
                  Phone_Number
                  Image {
                    alternativeText
                    url
                  }
                  imagetransforms
                  ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                  }
                }
              }
            }

            Intro_Video_Module {
              id
              Video_Link
              Video_Image {
                url
                alternativeText
              }
            }
          }
          
          ... on GLSTRAPI_ComponentComponentContentImageSection {
            id
            CTA_Label
            Content
            Heading
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 880
                    quality: 70
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
            Content_CTA:CTA_Link {
              Primary_URL
            }
            Other_Links {
              id
              Label
              Link_Image{
                alternativeText
                url

                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 880
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }
              }
              Link {
                Primary_URL
                
                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
              }
            }            
          }
          ... on GLSTRAPI_ComponentComponentShowNewsInsights {
            id
            Show_Hide
            Title
            Section_BG_Color
            Tab_List {
              id
              Tab_Name
              news_and_insights {
                id
                Title
                URL
                Service
                Embed_Video_URL
                news_and_insights_category {
                  Name
                }
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
                Image {
                  alternativeText
                  url
                }
              }            
            }
          }
          ... on GLSTRAPI_ComponentComponentThreeBlocksComponents {
            id
            Three_Blocks_Modules {
              Content
              Heading
              Custom_Link
              Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 920
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }
              }
              id
              menu_link {
                Primary_URL
                Secondary_URL

                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentOurPeopleComponent {
            id
            Heading
            Content
          }
          ... on GLSTRAPI_ComponentComponentLetGetStarted {
            id
            Video_Call_Link
            Phone_No
            Heading
            Contact_Email_Id
            Content
            Keep_In_Touch
          }

     
          ... on GLSTRAPI_ComponentComponentShowCustomerReviews {
            id
            Show_Customer_Reviews
            Review_BG_Color:Section_BG_Color
            Heading
            Background_Image{
              url
              alternativeText
            }
            reviews {
              Client_Name
              Content
              Sector
            }
          }
        }
      }
    }
}`
